<template>
  <tr>
    <td>
      <strong>{{ employee.id }}</strong>
    </td>
    <td class="px-0">
      <img v-if="employee.photo_url"
           :src="apiURL + employee.photo_url"
           :alt="employee.name"
           style="max-height: 50px;">
    </td>
    <td class="nowrap-column">
      <router-link :to="{ name: 'employee.details', params: { employeeId: employee.id }}"
                   class="btn btn-success pull-right margin-l-5"
                   title="Профиль сотрудника"
                   type="button">
        <i class="fa fa-user-circle"></i>
      </router-link>
    </td>
    <td class="nowrap-column">
      <span v-if="employee.is_day_off">
        <strong class="text-warning">
          {{ employee.name }}
        </strong>
        <small class="text-muted font-medium">Выходной</small>
        <br/>
        <small>
          <strong>В.О. </strong> {{ employee.day_off_responsible ? employee.day_off_responsible.name : '-' }}
        </small>
      </span>
      <span v-else>
        {{ employee.name }}
      </span>
      <br/>
      <small v-if="employee.branch" class="font-small">
        <strong>{{ employee.branch.title }}</strong>, {{ employee.branch.address }}
      </small>
    </td>
    <td class="nowrap-column">
      {{ employee.role.name }}
      <small v-if="employee.department">
        <br/>
        {{ employee.department.title }}
      </small>
    </td>
    <td>
      <a :href="'tel:+' + employee.phone">
        <strong>{{ employee.phone }}</strong>
      </a>
      <br/>
      <template v-if="employee.phone_two">
        <a :href="'tel:+' + employee.phone_two">
          <strong>{{ employee.phone_two }}</strong>
        </a>
        <br/>
      </template>
      <small>
        <a :href="'mailto:' + employee.email">
          {{ employee.email }}
        </a>
      </small>
    </td>
    <td class="nowrap-column">
      <strong>
        {{ employee.current_bonuses }}$
      </strong>
    </td>
    <td class="nowrap-column">
      <strong>
        {{ employee.cars_count }}
      </strong>
    </td>
    <td class="nowrap-column">
      {{ employee.updated_date }}
      <small v-if="employee.editor">
        <br/>
        Обновил <strong>{{ employee.editor.name }}</strong>
      </small>
    </td>
    <td class="nowrap-column">
      <button v-if="!employee.telegram_chat_id"
              class="btn btn-info"
              style="margin: 3px;"
              title="Привязать к Telegram-боту"
              type="button"
              v-on:click="setTelegramChatID">
        <i class="fab fa-telegram"></i>
      </button>

      <button v-if="!employee.telegram_chat_id_two && employee.phone_two"
              class="btn btn-info"
              style="margin: 3px;"
              title="Привязать к Telegram-боту (второй аккаунт)"
              type="button"
              v-on:click="setTelegramChatIDTwo">
        <i class="fab fa-telegram"></i> #2
      </button>

      <button class="btn btn-dark"
              style="margin: 3px;"
              title="Настроить параметры рассылки"
              type="button"
              v-on:click="showSetNotificationChannelsModal">
        <i class="fas fa-comments"></i>
      </button>

      <button v-if="employee.id !== 1"
              class="btn btn-default"
              style="margin: 3px;"
              title="Переместить все данные на другого сотрудника"
              type="button"
              v-on:click="showSetRelocateItemsModal">
        <i class="fas fa-boxes-packing"></i>
      </button>

      <router-link :to="{
                     name: 'employee.edit',
                     params: {
                       employeeId: employee.id
                     }
                   }"
                   class="btn btn-primary"
                   style="margin: 3px;"
                   title="Редактировать">
        <i class="fa fa-edit"></i>
      </router-link>

      <button class="btn btn-danger"
              style="margin: 3px;"
              title="Удалить"
              type="button"
              v-if="employee.id !== 1"
              v-on:click="removeEmployee(employee.id)">
        <i class="fa fa-trash"></i>
      </button>

      <button v-if="appDomain === 'autocredo.loc'"
              class="btn btn-dark"
              style="margin: 3px;"
              title="DEV auth"
              type="button"
              v-on:click="quickDevLogin(employee.email)">
        <i class="fa fa-right-to-bracket"></i>
      </button>
    </td>

    <modal :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'set_relocate_items_modal_' + employee.id"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade"
           @closed="hideSetRelocateItemsModal">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4>
            Передать все данные другому сотруднику
          </h4>
        </div>

        <div class="box-body">
          <set-relocate-items-form :employee_id="employee.id"/>
        </div>
      </div>
    </modal>

    <modal :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'set_notification_channels_modal_' + employee.id"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade"
           @closed="hideSetNotificationChannelsModal">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4>
            Настроить параметры рассылок
          </h4>
        </div>

        <div class="box-body">
          <set-notification-channels-form
            :employee_id="employee.id"
            :employee_notification_channels="employee.notification_channels"/>
        </div>
      </div>
    </modal>
  </tr>
</template>

<script>
import Vue from "vue";
import VuejsDialog from 'vuejs-dialog';
import AuthService from "@/services/AuthService";

Vue.use(VuejsDialog);

Vue.component('set-relocate-items-form', (resolve) => {
  return require(['./set-relocate-items-form.vue'], resolve);
});
Vue.component('set-notification-channels-form', (resolve) => {
  return require(['./set-notification-channels-form.vue'], resolve);
});

export default {
  name: 'item',

  components: {},

  props: {
    employee: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  data: () => ({
    apiURL: process.env.VUE_APP_API_URL,
    appDomain: process.env.VUE_APP_MAIN_DOMAIN,
  }),

  methods: {
    setTelegramChatID() {
      if (!this.employee.telegram_chat_id) {
        API.apiClient.put('/employees/set_telegram/' + this.employee.id).then((response) => {
          this.EventBus.$emit('table-filter-changed');
          showSuccess(response.data);
        }).catch((error) => {
          errorHandler(error);
        });
      }
    },
    setTelegramChatIDTwo() {
      if (!this.employee.telegram_chat_id_two) {
        API.apiClient.put('/employees/set_telegram_two/' + this.employee.id).then((response) => {
          this.EventBus.$emit('table-filter-changed');
          showSuccess(response.data);
        }).catch((error) => {
          errorHandler(error);
        });
      }
    },
    removeEmployee(employeeId) {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/employees/' + employeeId).then(() => {
          this.EventBus.$emit('table-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },

    showSetRelocateItemsModal() {
      this.$modal.show('set_relocate_items_modal_' + this.employee.id);
    },
    hideSetRelocateItemsModal() {
      this.$modal.hide('set_relocate_items_modal_' + this.employee.id);
    },

    showSetNotificationChannelsModal() {
      this.$modal.show('set_notification_channels_modal_' + this.employee.id);
    },
    hideSetNotificationChannelsModal() {
      this.$modal.hide('set_notification_channels_modal_' + this.employee.id);
    },

    async quickDevLogin(email) {
      const payload = {
        email: email,
        password: 'c12345',
        devAuth: true,
      };
      try {
        await AuthService.login(payload);
        const authUser = await this.$store.dispatch("auth/getAuthUser");

        if (authUser) {
          await this.$router.push("/");
        } else {
          console.log("Unable to fetch user after login, check your API settings.");
        }
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>

<style scoped>

</style>
